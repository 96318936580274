import { BaseService } from './base.service.js';
class KbqaService extends BaseService {
    async generateDocumentIndex(dto) {
        await this.fetch('/kbqa/generate-document-index', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
}
export const kbqaService = new KbqaService();
