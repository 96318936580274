"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_NAME = exports.getAppName = exports.EMAIL = void 0;
exports.EMAIL = 'contact@vinoai.net';
const getAppName = () => {
    if (!globalThis.location) {
        return 'unknown';
    }
    const { hostname } = globalThis.location;
    return hostname.split('.')[0];
};
exports.getAppName = getAppName;
exports.APP_NAME = (0, exports.getAppName)();
