"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractRedirectAccessToken = exports.ssoLogoutPageURL = exports.ssoRegisterPageURL = exports.ssoLoginInternalPageURL = exports.ssoLoginPageURL = exports.getSSOLogoutPageAuthURL = exports.getSSORegisterPageAuthURL = exports.getSSOLoginInternalPageAuthURL = exports.getSSOLoginPageAuthURL = exports.parseJWT = exports.sha256 = void 0;
const config_1 = require("./config");
const url_1 = require("./url");
// 通用的 SHA-256 函数，可在前后端通用
async function sha256(message) {
    if (typeof window !== 'undefined' && window.crypto && window.crypto.subtle) {
        // 在支持 Web Crypto API 的环境（如浏览器）下使用
        const msgBuffer = new TextEncoder().encode(message);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }
    else if (typeof require !== 'undefined') {
        // 在 Node.js 等环境下使用 Node.js 的 crypto 模块
        const crypto = await import('crypto');
        return crypto.createHash('sha256').update(message).digest('hex');
    }
    else {
        throw new Error('SHA-256 is not supported in this environment');
    }
}
exports.sha256 = sha256;
const parseJWT = (jwt = '') => {
    if (!jwt) {
        console.error(`Invalid JWT: ${jwt}`);
        return;
    }
    const [, payload] = jwt.split('.');
    try {
        return JSON.parse(atob(payload));
    }
    catch (err) {
        console.error(`Error parsing JWT: ${jwt}`);
        return;
    }
};
exports.parseJWT = parseJWT;
const getAuthParam = (source = config_1.APP_NAME, redirect) => {
    const param = new URLSearchParams();
    if (redirect) {
        param.set('redirect', redirect);
    }
    else if (globalThis.location) {
        const url = new URL(globalThis.location.href);
        // remove access token
        if (url.searchParams.has('access_token')) {
            url.searchParams.delete('access_token');
        }
        param.set('redirect', url.toString());
    }
    param.set('source', source);
    return param;
};
const getSSOLoginPageAuthURL = (source = config_1.APP_NAME, redirect) => {
    const param = getAuthParam(source, redirect);
    return `${url_1.SSO_LOGIN_PAGE_URL}?${param}`;
};
exports.getSSOLoginPageAuthURL = getSSOLoginPageAuthURL;
const getSSOLoginInternalPageAuthURL = (source = config_1.APP_NAME, redirect) => {
    const param = getAuthParam(source, redirect);
    return `${url_1.SSO_LOGIN_INTERNAL_PAGE_URL}?${param}`;
};
exports.getSSOLoginInternalPageAuthURL = getSSOLoginInternalPageAuthURL;
const getSSORegisterPageAuthURL = (source = config_1.APP_NAME, redirect) => {
    const param = getAuthParam(source, redirect);
    return `${url_1.SSO_REGISTER_PAGE_URL}?${param}`;
};
exports.getSSORegisterPageAuthURL = getSSORegisterPageAuthURL;
const getSSOLogoutPageAuthURL = (source = config_1.APP_NAME, redirect) => {
    const param = getAuthParam(source, redirect);
    return `${url_1.SSO_LOGOUT_PAGE_URL}?${param}`;
};
exports.getSSOLogoutPageAuthURL = getSSOLogoutPageAuthURL;
exports.ssoLoginPageURL = (0, exports.getSSOLoginPageAuthURL)();
exports.ssoLoginInternalPageURL = (0, exports.getSSOLoginInternalPageAuthURL)();
exports.ssoRegisterPageURL = (0, exports.getSSORegisterPageAuthURL)();
exports.ssoLogoutPageURL = (0, exports.getSSOLogoutPageAuthURL)();
const extractRedirectAccessToken = () => {
    const url = new URL(document.location.href);
    const accessToken = url.searchParams.get('access_token');
    url.searchParams.delete('access_token');
    history.replaceState(null, '', url.toString());
    return accessToken;
};
exports.extractRedirectAccessToken = extractRedirectAccessToken;
