import { getBaseUrl, ssoLoginPageURL } from '@ai-agent/util';
export class BaseService {
    constructor() {
        this.BASE_URL = getBaseUrl('api');
    }
    get accessToken() {
        return localStorage.getItem(BaseService.ACCESS_TOKEN_KEY) ?? '';
    }
    set accessToken(token) {
        localStorage.setItem(BaseService.ACCESS_TOKEN_KEY, token);
    }
    get userContext() {
        const accessToken = this.accessToken;
        if (!accessToken) {
            return undefined;
        }
        const [, dataString] = accessToken.split('.');
        try {
            return JSON.parse(atob(dataString));
        }
        catch {
            return undefined;
        }
    }
    get validAccessToken() {
        const userContext = this.userContext;
        if (!userContext) {
            return false;
        }
        const { exp, id } = userContext;
        return Date.now() / 1000 < exp && id > 0;
    }
    /**
     * 获取当前用户ID，视为用户已经登录，减少判空次数(仅在确认登录的情况下使用)
     */
    get userId() {
        const validAccessToken = this.validAccessToken;
        const userContext = this.userContext;
        if (!validAccessToken || !userContext) {
            throw new Error('invalid access token');
        }
        return userContext.id;
    }
    /**
     * 将子路径转为完整url
     * @param path 子路径
     * @returns 完整url
     */
    fullPath(path) {
        if (path.startsWith('http')) {
            return path;
        }
        if (!this.BASE_URL.endsWith('/') && !path.startsWith('/')) {
            return `${this.BASE_URL}/${path}`;
        }
        if (this.BASE_URL.endsWith('/') || path.startsWith('/')) {
            return `${this.BASE_URL}${path}`;
        }
        return `${this.BASE_URL}${path.slice(1)}`;
    }
    /**
     * 对fetch进行包装，在请求前后进行额外操作
     * @param path 请求路径
     * @param options 请求参数
     * @returns Response
     */
    async fetch(path, options = {}) {
        if (this.accessToken) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${this.accessToken}`,
            };
        }
        const url = this.fullPath(path);
        const res = await fetch(url, options);
        if (!res.ok) {
            if (res.status === 401) {
                console.warn('Session expired');
                localStorage.clear();
                location.replace(ssoLoginPageURL);
            }
            throw new Error(res.statusText);
        }
        const authorization = res.headers.get('Set-Authorization') ?? '';
        const [, token] = authorization.split(' ');
        if (token) {
            this.accessToken = token;
        }
        const contentType = res.headers.get('Content-Type');
        if (contentType?.includes('application/json')) {
            const responseBody = (await res.json());
            const { code, message } = responseBody;
            if (code !== 0) {
                console.warn(`API Exception: ${message}`);
                throw new Error(message);
            }
            return responseBody;
        }
        throw new Error(`unsupported Content-Type ${contentType}`);
    }
}
BaseService.ACCESS_TOKEN_KEY = 'access_token';
