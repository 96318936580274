import { jsx as _jsx } from "react/jsx-runtime";
import { userService } from '@ai-agent/service';
import { extractRedirectAccessToken, getSSOLoginInternalPageAuthURL, getSSOLoginPageAuthURL } from '@ai-agent/util';
import { DefaultFallback } from '@component/fallback';
import { LazyPage } from '@component/lazy-page';
import { createBrowserRouter, redirect } from 'react-router-dom';
export const router = createBrowserRouter([
    {
        path: '/',
        loader: () => {
            const token = extractRedirectAccessToken();
            if (token) {
                userService.injectAccessToken(token);
            }
            if (!userService.validAccessToken) {
                localStorage.clear();
                return redirect('/login');
            }
            return true;
        },
        element: _jsx(LazyPage, { page: "main" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/login/internal',
        loader: () => {
            localStorage.clear();
            const url = location.origin;
            return redirect(getSSOLoginInternalPageAuthURL(undefined, url));
        },
    },
    {
        path: '/login',
        loader: () => {
            localStorage.clear();
            const url = location.origin;
            return redirect(getSSOLoginPageAuthURL(undefined, url));
        },
    },
    {
        path: '/register',
        element: _jsx(LazyPage, { page: "register" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/greeting',
        loader: () => {
            const token = extractRedirectAccessToken();
            if (token) {
                userService.injectAccessToken(token);
            }
            if (!userService.validAccessToken) {
                localStorage.clear();
                return redirect('/login');
            }
            return true;
        },
        element: _jsx(LazyPage, { page: "greeting" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/loader',
        element: _jsx(LazyPage, { page: "loader" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    { path: '/__test__', element: _jsx(LazyPage, { page: "test" }) },
    {
        path: '*',
        loader: async () => redirect('/'),
        errorElement: _jsx(DefaultFallback, {}),
    },
]);
