import { BaseService } from './base.service.js';
import { timeConverter } from '@ai-agent/util';
class MessageService extends BaseService {
    async getPreviewMessages(sessionId) {
        const { data: messages } = await this.fetch(`/preview/session/${sessionId}/messages`);
        return messages.map(timeConverter);
    }
    async getSessionMessage(sessionId) {
        const { data: messages } = await this.fetch(`/message/in-session?sessionId=${sessionId}`);
        return messages.map(timeConverter);
    }
    async match(dto) {
        if (dto.content.length === 0) {
            return [];
        }
        const { data: messages } = await this.fetch('/message/match', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return messages.map(timeConverter);
    }
    async getMessages(ids) {
        if (ids.length === 0) {
            return [];
        }
        const { data: messages } = await this.fetch(`/message?ids=${ids.join(',')}`);
        return messages.map(timeConverter);
    }
    async operate(dto) {
        const { data: operation } = await this.fetch('/message/operate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(operation);
    }
    async revokeOperation(dto) {
        const { data: operation } = await this.fetch('/message/revoke', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(operation);
    }
    async getOperations(messageId) {
        const res = await this.fetch(`/message/${messageId}/operations`);
        return res.data.map(timeConverter);
    }
    async bulkFetchOperations(messageIds) {
        if (messageIds.length === 0) {
            return [];
        }
        const res = await this.fetch(`/message/operations?ids=${messageIds.join(',')}`);
        return res.data.map(timeConverter);
    }
    async getLatestMessages(sessionIds) {
        if (sessionIds.length === 0) {
            return [];
        }
        const res = await this.fetch(`/message/latest?sessionIds=${sessionIds.join(',')}`);
        return res.data.map(timeConverter);
    }
    async bulkSendMessage(dto) {
        return this.fetch('/message/bulk-send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async filter(dto) {
        const res = await this.fetch('/message/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return res;
    }
    async remove(ids) {
        await this.fetch(`/message/remove?ids=${ids.join(',')}`, { method: 'DELETE' });
    }
}
export const messageService = new MessageService();
