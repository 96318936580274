"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_USER = void 0;
exports.DEFAULT_USER = {
    id: 0,
    account: '',
    nickname: '',
    avatar: '',
    role: "user" /* UserRole.user */,
    desc: null,
    cms: false,
    chat: false,
    chatTools: '',
    email: null,
    phone: null,
    weixin: null,
    org: null,
    title: null,
    remainingSendingTimes: 0,
    totalSendingTimes: 0,
    inviteCode: '',
    inviterId: null,
    source: '',
    ctime: new Date(),
    utime: new Date(),
    dtime: null,
};
